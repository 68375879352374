import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomepageComponent } from './views/homepage/homepage.component';
import { VisionComponent } from './views/vision/vision.component';
import { ServicesComponent } from './views/services/services.component';
import { ContactComponent } from './views/contact/contact.component';
import { CustomerhappinessComponent } from './views/customerhappiness/customerhappiness.component';
import { InterrogationsComponent } from './views/interrogations/interrogations.component';
import { TerminologyComponent } from './views/terminology/terminology.component';
import { CurrencyconverterComponent } from './views/currencyconverter/currencyconverter.component';
import { DimensionsComponent } from './views/dimensions/dimensions.component';
import { TariffComponent } from './views/tariff/tariff.component';
import { RegulationComponent } from './views/regulation/regulation.component';
const routes: Routes = [
    { path: '', component: HomepageComponent },
    { path: 'kurumsal/vizyonumuz-ve-misyonumuz', component: VisionComponent },
    { path: 'kurumsal/musteri-memnuniyeti', component: CustomerhappinessComponent },
    { path: 'hizmetler/:id', component: ServicesComponent },
    { path: 'sorgulamalar/:id', component: InterrogationsComponent },
    { path: 'bilgi-bankasi/terimler', component: TerminologyComponent },
    { path: 'bilgi-bankasi/olculer', component: DimensionsComponent },
    { path: 'bilgi-bankasi/tarife-olcusu', component: TariffComponent },
    { path: 'bilgi-bankasi/dunya-doviz-cevirici', component: CurrencyconverterComponent },
    { path: 'bilgi-bankasi/kanun-ve-yonetmelik', component: RegulationComponent },
    { path: 'iletisim', component: ContactComponent }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
