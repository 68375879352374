import { Component, OnInit, ViewEncapsulation } from '@angular/core';



@Component({
  selector: '[homepage]',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class HomepageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
