import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { HomepageComponent } from './views/homepage/homepage.component';
import { VisionComponent } from './views/vision/vision.component';
import { TextpagesComponent } from './components/textpages/textpages.component';

import { PdfViewerModule } from 'ng2-pdf-viewer';
import { IncotermsComponent } from './components/incoterms/incoterms.component';
import { DatabankComponent } from './views/databank/databank.component';
import { ServicesComponent } from './views/services/services.component';
import { ContactComponent } from './views/contact/contact.component';
import { CustomerhappinessComponent } from './views/customerhappiness/customerhappiness.component';
import { InterrogationsComponent } from './views/interrogations/interrogations.component';
import { TerminologyComponent } from './views/terminology/terminology.component';
import { DimensionsComponent } from './views/dimensions/dimensions.component';
import { CurrencyconverterComponent } from './views/currencyconverter/currencyconverter.component';
import { TariffComponent } from './views/tariff/tariff.component';
import { RegulationComponent } from './views/regulation/regulation.component';
import { AgmCoreModule } from '@agm/core';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomepageComponent,
    VisionComponent,
    TextpagesComponent,
    IncotermsComponent,
    DatabankComponent,
    ServicesComponent,
    ContactComponent,
    CustomerhappinessComponent,
    InterrogationsComponent,
    TerminologyComponent,
    DimensionsComponent,
    CurrencyconverterComponent,
    TariffComponent,
    RegulationComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    PdfViewerModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBzTqOMXq5-9v7mluiU3VrKUrC9EbDgBF4'
    })
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
