import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: '[databank]',
  templateUrl: './databank.component.html',
  styleUrls: ['./databank.component.css']
})
export class DatabankComponent implements OnInit {
  id: any = [];
  src: any;
  constructor(
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.id = params.get("id");
      // this.getNewsList();
      this.getPdf();
    });
  }
  getPdf() {
    switch (this.id) {
      case 'gumruk-kanunu-ve-yonetmenligi':
        this.src = './assets/pdf/gümrük-kanunu.pdf';
      case 'turk-ticaret-kanunu':
        this.src = './assets/pdf/turk-ticaret-kanunu.pdf';
      case 'kacakcilik-kanunu':
        this.src = './assets/pdf/kacakcilik-kanunu.pdf';
      case 'tarife-cetveli':
        this.src = '../assets/pdf/tarife-cetveli.pdf';
    }

  }

}
