import { Component, OnInit } from '@angular/core';

@Component({
  selector: '[textpages]',
  templateUrl: './textpages.component.html',
  styleUrls: ['./textpages.component.css']
})
export class TextpagesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
