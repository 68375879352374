import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-customerhappiness',
  templateUrl: './customerhappiness.component.html',
  styleUrls: ['./customerhappiness.component.css']
})
export class CustomerhappinessComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
