import { Component, OnInit, ɵcompileNgModuleFactory__POST_R3__ } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-interrogations',
  templateUrl: './interrogations.component.html',
  styleUrls: ['./interrogations.component.css']
})
export class InterrogationsComponent implements OnInit {
  id: any = [];
  constructor(
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.id = params.get("id");
    });
  }

  width: "100%"; // width in pixels or percentage
  height: "300"; // height in pixels
  latitude: "0.00"; // center latitude (decimal degrees)
  longitude: "0.00"; // center longitude (decimal degrees)
  zoom: "3"; // initial zoom (between 3 and 18)
  names: false; // always show ship names (defaults to false)

  // Single ship tracking
  //   var mmsi="123456789";     // display latest position (by MMSI)
  //    var imo="1234567";        // display latest position (by IMO, overrides MMSI)
  //    var show_track=false;     // display track line (last 24 hours)

  // Fleet tracking
  //     var fleet="e48ab3d80a0e2a9bf28930f2dd08800c"; // your personal Fleet key (displayed in your User Profile)
  //     var fleet_name="Carnival"; // display particular fleet from your fleet list
  //     var fleet_timespan="1440"; // maximum age in minutes of the displayed ship positions

  aismap() {

  }

}
