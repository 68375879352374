import { Component, OnInit } from '@angular/core';

@Component({
  selector: '[vision]',
  templateUrl: './vision.component.html',
  styleUrls: ['./vision.component.css']
})
export class VisionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
