import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

declare var $: any;
declare var sliderinit: any;
@Component({
  selector: '[header]',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {

  id: any;
  checkPage: boolean;
  title: any;

  constructor(
    private router: Router
  ) {
    this.router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        e.url == "/" ? this.checkPage = false : this.checkPage = true;


      }

      if (this.checkPage) {
        sliderinit();
      }

    });
  }


  ngOnInit() {
    // this.route.paramMap.subscribe(params => {
    //   this.id = params.get("id");
    //   console.log(this.id);
    //   this.id == "" ? this.checkPage = false : this.checkPage = true;

    // })
    $('#left-menu').metisMenu({
      toggle: false // disable the auto collapse. Default: true.
    });

    /*=== Show left slide menu ====*/

    $('#show-menu').on('click', function () {
      console.log("asdfasdf");
      $("#slide-menu").css('left', '0');
      $("#black-overlay").css('display', 'block');
      $("#black-overlay").css('opacity', '1');

      return false;

    });

    /*=== Close left slide menu ====*/

    $('#close-menu, #black-overlay').on('click', function () {

      $("#slide-menu").css('left', '-290px');
      $("#black-overlay").css('opacity', '0');
      setTimeout(function () {
        $("#black-overlay").css('display', 'none');
      }, 300)

      return false;

    });

  }

}
